import React from 'react';
import './about.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import logo from '../../../assets/images/logo.png';
import facebook from '../../../assets/images/facebookLogo.png';
import whatsapp from '../../../assets/images/whatsappLogo.png';
import building from '../../../assets/images/hymusBuilding.jpg';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});


function About() {
    const position = [43.72121465947508, -79.27902886466526]; // Replace with your desired coordinates

    return (
        <div className='about-section' id='about'>
            <div className='about-body'>
                <div className='map-cont'>
                    <MapContainer center={position} zoom={16} className="leaflet-map">
                        <img className='building-img' src={building}/>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={position}>
                            <Popup>
                                Hymus Sports <br /> 200 Hymus Road, Scarborough, ON M1L 2E1
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
            <div className='about-footer'>
                <div className='about-footer-img'>
                    <img className='footer-logo-img' src={logo} />
                </div>
                <div className='about-footer-txt'>
                    <h3 className='footer-txt-title' style={{ textDecoration: 'underline' }}>Contact Information</h3>
                    <p className='footer-txt-content'>Booking: 647-886-1197</p>
                    <p className='footer-txt-content'>Front Desk: 416-750-4444</p>
                    <p className='footer-txt-content'>Inquiries: 416-450-1197</p>
                </div>
                <div className='about-footer-txt'>
                    <h3 className='footer-txt-title' style={{ textDecoration: 'underline' }}>Location</h3>
                    <p className='footer-txt-content'>200 Hymus Road</p>
                    <p className='footer-txt-content'>Scarborough, Ontario, M1L 2E1</p>
                </div>
                <div className='about-footer-txt'>
                    <h3 className='footer-txt-title' style={{ textDecoration: 'underline' }}>Hours of Operation</h3>
                    <p className='footer-txt-content'>Open 7 Days</p>
                    <p className='footer-txt-content'>24 Hours</p>
                </div>
                <div className='about-footer-socials'>
                    <a className='footer-social-link' href='https://www.facebook.com/p/Hymus-Sports-100076158014252/' target='_blank' rel="noreferrer">
                        <img className='footer-social-img' src={facebook} />
                    </a>
                    <a className='footer-social-link' href='https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B14164501197%26context%3DARBHBl8MQGwKr3yL7a9YpEsQppPOPDa9kMfpgaQx8qjLgV6Kun-s38C6rfYi4-gMxbZWN2Fct5Mcn2o6XsvCMJU7FAlouombsj3JcwxkMVc4wlum4GoWcHEb9LGv_lKbRUmFvdHcIi7jtRq8LJcsdAPHIg%26source%3DFB_Page%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwZXh0bgNhZW0CMTAAAR2JB9ifszFpzryg6jAyOZm0M1XD7_kj6rok-MUn7-5Y_cTMi2ppRHfmHeI_aem_hLIumli62mL8qY8D3oUhog&h=AT2Hhr3V0FWVdz_i95ijZHqbtl-qgANxWKbE6tcsL1Myg4BgZGBpUI69EDkQ9cms9NYBZhVuplJti2jZxA86KPWe6Iby_ZoNDUbK0P6Ud2PwUqzS1drsc2PL2C2KeG94ydmthg' target='_blank' rel="noreferrer">
                        <img className='footer-social-img' src={whatsapp} />
                    </a>          
                </div>
                <div className='copy-right'>
                    <p className='copy-right-txt'>&copy; 2024 Hymus Sports. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default About;